import GAException from 'src/components/GAException';
import { getData } from 'src/hooks/useRegistrarActividadDimo';

import {
  account_service_api_url as API_URL,
  dimo_service_api_url as API_URL_DIMO,
  account_service_api_url,
} from 'src/services/backendApiService';
import incidenciasService, { LISTA_INCIDENCIAS } from 'src/services/incidenciasService';
import axios from '../utils/axios';
import { defaultResponseError } from './transactionService';
import TokenService from './TokenService';

const API_REQUEST_HEADER = {
  appVersion: '1.0.2',
  token: 'prueba',
  user: 'csoto'
};

const API_REQUEST_PHONE_DATA = {
  codigo: 'lkajsdlkjasd',
  imei: localStorage.getItem("deviceId") ? localStorage.getItem("deviceId") : "a1s6d165s1d6asd",
  // imei: 'a1s6d165s1d6asd',
  modelo: 'Iphone 11',
  sistemaOperativo: 'IOS11'
};

const CONFIG = {
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
};

const KEY_DATA_VALIDATION = {
  CEDULA: 'DIMO_CATASTRO_CI',
  NOMBRECOMPLETO: 'NOMBRECOMPLETO',
  NOMBRES: 'DIMO_CATASTRO_NOMBRES',
  APELLIDOS: 'DIMO_CATASTRO_APELLIDOS',
  DIA_NACIMIENTO: 'DIMO_CATASTRO_NACIMIENTO_DIA',
  MES_NACIMIENTO: 'DIMO_CATASTRO_NACIMIENTO_MES',
  ANIO_NACIMIENTO: 'DIMO_CATASTRO_NACIMIENTO_ANIO',
};

const HEADERS_WITH_TOKEN = () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${TokenService.getLocalAccessToken()}`
  };
  return headers;
};

class AccountService {
  registerUser = (catastro) => new Promise((resolve, reject) => {
    const catastroPrm = {
      nombre: catastro.firstName,
      apellido: catastro.lastName,
      cedula: catastro.dniNumber,
      email: catastro.email,
      numeroTelefono: catastro.mobileNumber.toString().length == 10 ? catastro.mobileNumber.toString() : (`0${catastro.mobileNumber.toString()}`),
      password: catastro.password,
      flagTarjetaPreemitida: '0',
      numeroTarjetaPreemitida: '0',
      flagUsuarioMiCabal: catastro.hasOwnProperty('flagUsuarioMiCabal') ? catastro.flagUsuarioMiCabal : false,
      codPromotorRef: catastro.hasOwnProperty('codPromotorRef') ? (`${catastro.codPromotorRef}`) : '',
    };

    try {
      axios.post(`${API_URL}/tarjetaPrepaga/v1/RegistroDimoPaso`, { requestHeaderApp: API_REQUEST_HEADER, phoneData: API_REQUEST_PHONE_DATA, catastroRequest: catastroPrm })
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ message: response.data.header.txtReturn });
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  registroDimoPorAfinidad = (RegistroDimo) => new Promise((resolve, reject) => {
    axios.post(`${API_URL}/tarjetaPrepaga/v1/RegistroDimo/afinidad`, { catatroDimoAfinidad: RegistroDimo })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  })

  dataURItoBlob = (dataURI) => {
    // const byteString = atob(dataURI.split(',')[1]);
    // const ab = new ArrayBuffer(byteString.length);
    // const ia = new Uint8Array(ab);
    // for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i); }
    // return new Blob([ab], { type: 'image/jpeg' });

    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    // const byteString = atob(dataURI.split(',')[1]);
    //usar buffer para evitar error de atob
    const byteString = Buffer.from(dataURI.split(',')[1], 'base64').toString('binary');

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  validateUserData = (photoData, policeData, validacionKeysList) => {
    const dataValid = {
      photoData,
      policeData,
      validacionKeysList,
    };

    this.registrarActividadFrontend('CompletarRegistro', '/app/completar/registro/validacion', getData('', dataValid));

    function porcentajeCoincidencia(str1, str2) {
      const v_str1 = `${str1}`;
      const v_str2 = `${str2}`;
      let tmpValue = 0;
      let tmpValueLTR = 0;
      let tmpValueRTL = 0;
      let minLength = v_str1.length;
      let maxLength = v_str1.length;

      if (v_str1.length > v_str2.length) {
        minLength = v_str2.length;
      }

      if (v_str1.length < v_str2.length) {
        maxLength = v_str2.length;
      }

      for (var i = 0; i < minLength; i++) {
        if (v_str1[i] == v_str2[i]) {
          tmpValueLTR++;
        }
      }

      for (var i = 0; i < maxLength; i++) {
        const indice = maxLength - i - 1;
        if (v_str1[v_str1.length - i] && v_str1[v_str1.length - i] == v_str2[v_str2.length - i]) {
          tmpValueRTL++;
        }
      }

      tmpValue = tmpValueLTR > tmpValueRTL ? tmpValueLTR : tmpValueRTL;

      const weight = tmpValue / maxLength;
      return Math.trunc(weight * 100);
    }

    const isDatoValido = (keyData, dato1, dato2) => {
      for (let i = 0; i < validacionKeysList.length; i++) {
        const dataKey = validacionKeysList[i];

        if (dataKey.codigo === keyData) {
          // console.log("keyData", keyData, "dato1: " + dato1, "dato2: " + dato2);
          // console.log("coincidencia", porcentajeCoincidencia(dato1, dato2));
          // console.log("dataKey.porcentaje",  dataKey.porcentaje  );

          if (porcentajeCoincidencia(dato1, dato2) >= dataKey.porcentaje) {
            return true;
          }
          return false;
        }
      }
      return false;
    };

    // Nueva validacion para comparar los datos de la cedula con los datos de la policia
    // se compara si los datos no esten null o undefinied de la cedula y la policia
    if(!(photoData && photoData.data) && !(policeData && policeData.data)){
      console.log("Error al leer los datos la Cedula / Datos Policia - photoData: ", photoData, " - policeData: ", policeData);
      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_LEER_DATOS.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, '')
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });
  
      GAException(
        {
          message: `Error al leer los datos la Cedula / Datos Policia - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - Algún dato es nulo o no existe`
        }
      );
  
      return {validation: false , message: 'No se pudo obtener una coincidencia en los datos de la cedula y la policia.'};
    }
    // se compara si los datos no esten null o undefinied de la fecha de nacimiento de la cedula y la policia
    if (!photoData.data.dateOfBirth && !policeData.data.dateOfBirth) {
      console.log("Error al validar fecha de nacimiento - photoData: ", photoData, " - policeData: ", policeData);
      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_LEER_DATOS.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, '')
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });
  
      GAException(
        {
          message: `Error al validar año de nacimiento - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - la fecha de nacimiento es nula o no existe`
        }
      );
  
      return {validation: false , message: 'No se pudo obtener una coincidencia en la fecha de nacimiento.'};
    }

    // compara si el año de nacimiento es igual
    if (!isDatoValido(KEY_DATA_VALIDATION.ANIO_NACIMIENTO, photoData.data.dateOfBirth.year, policeData.data.dateOfBirth.year)) {
      console.log("Error al validar año de nacimiento - photoData: ", photoData, " - policeData: ", policeData);
      const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.dateOfBirth.year, policeData.data.dateOfBirth.year)}`;

      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_ANHO_CI_VS_PN.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

      GAException(
        {
          message: `Error al validar año de nacimiento - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - coincidencia %: ${porcenCoincidencia}`
        }
      );

      return {validation: false , message: 'No se pudo obtener una coincidencia en el año de nacimiento.'};
    }

    // compara si el mes de nacimiento es igual
    if (!isDatoValido(KEY_DATA_VALIDATION.MES_NACIMIENTO, photoData.data.dateOfBirth.month, policeData.data.dateOfBirth.month)) {
      console.log("Error al validar mes de nacimiento - photoData: ", photoData, " - policeData: ", policeData);
      const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.dateOfBirth.month, policeData.data.dateOfBirth.month)}`;

      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_MES_CI_VS_PN.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });
  
      GAException(
        {
          message: `Error al validar mes de nacimiento - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - coincidencia %: ${porcenCoincidencia}`
        }
      );
      return {validation: false , message: 'No se pudo obtener una coincidencia en el mes de nacimiento.'};
    }

    // compara si el dia de nacimiento es igual
    if (!isDatoValido(KEY_DATA_VALIDATION.DIA_NACIMIENTO, photoData.data.dateOfBirth.day, policeData.data.dateOfBirth.day)) {
      console.log("Error al validar dia de nacimiento - photoData: ", photoData, " - policeData: ", policeData);
      const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.dateOfBirth.day, policeData.data.dateOfBirth.day)}`;

      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_DIA_CI_VS_PN.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

      GAException(
        {
          message: `Error al validar dia de nacimiento  - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - coincidencia %: ${porcenCoincidencia}`
        }
      );
      // console.log("day",porcentajeCoincidencia(photoData.data.dateOfBirth.day, policeData.data.dateOfBirth.day))
      return {validation: false , message: 'No se pudo obtener una coincidencia en el dia de nacimiento.'};
    }

    // compara si los nombres son iguales
    if (!isDatoValido(KEY_DATA_VALIDATION.NOMBRES, photoData.data.givenNames, policeData.data.givenNames)) {
      console.log("Error al validar nombres - photoData: ", photoData, " - policeData: ", policeData);
      const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.givenNames, policeData.data.givenNames)}`;

      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_NOMBRES_CI_VS_PN.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });
  
      GAException(
        {
          message: `Error al validar los nombres - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - coincidencia %: ${porcenCoincidencia}`
        }
      );
      return {validation: false , message: 'No se pudo obtener una coincidencia en los nombres.'};
    }

    // compara si los apellidos son iguales
    if (!isDatoValido(KEY_DATA_VALIDATION.APELLIDOS, photoData.data.surname, policeData.data.surname)) {
      console.log("Error al validar apellidos - photoData: ", photoData, " - policeData: ", policeData);
      const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.surname, policeData.data.surname)}`;

      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_APELLIDOS_CI_VS_PN.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
      };

      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

      GAException(
        {
          message: `Error al validar los apellidos - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - coincidencia %: ${porcenCoincidencia}`
        }
      );

      return {validation: false , message: 'No se pudo obtener una coincidencia en los apellidos.'};
    }
    
    console.log("Validacion de datos correcta - photoData: ", photoData, " - policeData: ", policeData);
    return {validation: true , message: 'Validacion de datos correcta.'};

    /*// Validacion Vieja de la cedula
    if ((photoData && photoData.data) && (policeData && policeData.data)) {
      // se compara si los datos no esten null o undefinied
      if (photoData.data.dateOfBirth && policeData.data.dateOfBirth) {
        // compara si el año de nacimiento es igual
        if (isDatoValido(KEY_DATA_VALIDATION.ANIO_NACIMIENTO, photoData.data.dateOfBirth.year, policeData.data.dateOfBirth.year)) {
          // compara si el mes de nacimiento es igual
          if (isDatoValido(KEY_DATA_VALIDATION.MES_NACIMIENTO, photoData.data.dateOfBirth.month, policeData.data.dateOfBirth.month)) {
            // compara si el dia de nacimiento es igual
            if (isDatoValido(KEY_DATA_VALIDATION.DIA_NACIMIENTO, photoData.data.dateOfBirth.day, policeData.data.dateOfBirth.day)) {
              // compara si los nombres son iguales
              if (isDatoValido(KEY_DATA_VALIDATION.NOMBRES, photoData.data.givenNames, policeData.data.givenNames)) {
                // compara si los apellidos son iguales
                if (isDatoValido(KEY_DATA_VALIDATION.APELLIDOS, photoData.data.surname, policeData.data.surname)) {
                  return true;
                }

                const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.surname, policeData.data.surname)}`;

                const registroIncidencia = {
                  idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_APELLIDOS_CI_VS_PN.id,
                  datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
                };

                incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

                GAException(
                  {
                    message: `Error al validar los apellidos - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
                      } - coincidencia %: ${porcenCoincidencia}`
                  }
                );

                return false;
              }

              const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.givenNames, policeData.data.givenNames)}`;

              const registroIncidencia = {
                idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_NOMBRES_CI_VS_PN.id,
                datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
              };
              incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

              GAException(
                {
                  message: `Error al validar los nombres - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
                    } - coincidencia %: ${porcenCoincidencia}`
                }
              );
              return false;
            }
            const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.dateOfBirth.day, policeData.data.dateOfBirth.day)}`;

            const registroIncidencia = {
              idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_DIA_CI_VS_PN.id,
              datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
            };
            incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

            GAException(
              {
                message: `Error al validar dia de nacimiento  - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
                  } - coincidencia %: ${porcenCoincidencia}`
              }
            );
            // console.log("day",porcentajeCoincidencia(photoData.data.dateOfBirth.day, policeData.data.dateOfBirth.day))
            return false;
          }
          const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.dateOfBirth.month, policeData.data.dateOfBirth.month)}`;

          const registroIncidencia = {
            idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_MES_CI_VS_PN.id,
            datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
          };
          incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

          GAException(
            {
              message: `Error al validar mes de nacimiento - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
                } - coincidencia %: ${porcenCoincidencia}`
            }
          );
          return false;
        }

        const porcenCoincidencia = `${porcentajeCoincidencia(photoData.data.dateOfBirth.year, policeData.data.dateOfBirth.year)}`;

        const registroIncidencia = {
          idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_ANHO_CI_VS_PN.id,
          datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, porcenCoincidencia)
        };
        incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

        GAException(
          {
            message: `Error al validar año de nacimiento - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
              } - coincidencia %: ${porcenCoincidencia}`
          }
        );
        return false;
      }

      const registroIncidencia = {
        idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_LEER_DATOS.id,
        datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, '')
      };
      incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

      GAException(
        {
          message: `Error al validar año de nacimiento - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
            } - la fecha de nacimiento es nula o no existe`
        }
      );

      return false;
    }

    const registroIncidencia = {
      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_LEER_DATOS.id,
      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData, policeData, '')
    };
    incidenciasService.crearIncidenciaRegistro(registroIncidencia).then(() => { }).catch(() => { });

    GAException(
      {
        message: `Error al leer los datos la Cedula / Datos Policia - photoData: ${JSON.stringify(photoData)} - policeData: ${JSON.stringify(policeData)
          } - Algún dato es nulo o no existe`
      }
    );

    return false;*/
  }

  registerPicture = (front, rear, jsonData, token, selfie) => new Promise((resolve, reject) => {
    const URL = `${account_service_api_url}/v2/kyc/upload`;
    // let URL = '';
    const bodyFormData = new FormData();
    let fechaNacimiento = '';

    bodyFormData.append('front', this.dataURItoBlob(front));
    bodyFormData.append('rear', this.dataURItoBlob(rear));
    bodyFormData.append('selfie', this.dataURItoBlob(selfie));
    bodyFormData.set(
      'jsondata',
      JSON.stringify(jsonData)
    );

    if (token === '') {
      token = localStorage.getItem('accessToken');
    }

    const auxSelfie = selfie;

    axios.post(
      URL,
      bodyFormData,
      {
        headers: {
          ContentType: 'multipart/form-data',
          // Authorization: 'Basic Y2FiYWw6Q0BiQGwxNTA2',
          Authorization: `Bearer ${token}`,
        }
      },
    )
      .then((photoData) => {
        const policeRequest = {
          transactionId: jsonData.transactionId,
          documentNumber: jsonData.documentNumber
        };
        console.log('photoData==>', photoData);

        if (!photoData.data.codReturn) {

          axios.post(
            `${account_service_api_url}/v2/kyc/getperson`,
            policeRequest,
            {
              headers: {
                ContentType: 'application/json',
                // Authorization: 'Basic Y2FiYWw6Q0BiQGwxNTA2',
                Authorization: `Bearer ${token}`,
              }
            },
          )
            .then((policeData) => {

              if (photoData.data.code === 0 || photoData.data.code === 110) {
                // Parametros de Porcentaje de Aceptacion para la validacion...
                axios.post(`${API_URL_DIMO}/ws_validacion_parametro`, null, CONFIG)
                  .then((validaciones) => {
                    if (this.validateUserData(photoData.data, policeData.data, validaciones.data.data)?.validation) {
                      if (photoData.data.data.dateOfBirth) {
                        if (photoData.data.data.dateOfBirth.year) {
                          let { day } = photoData.data.data.dateOfBirth;
                          let month = `${photoData.data.data.dateOfBirth.month}`;
                          const { year } = photoData.data.data.dateOfBirth;

                          if (day < 10) {
                            day = `0${day}`;
                          }

                          if (month < 10) {
                            month = `0${month}`;
                          }

                          fechaNacimiento = `${day}/${month}/${year}`;
                        }
                      }

                      this.registerIdCardCheckStatus(
                        jsonData.documentNumber, jsonData.transactionId, 1, token, auxSelfie, fechaNacimiento
                      )
                        .then((responseCheck) => {
                          const resp = responseCheck.data;
                          resp.fechaNacimiento = fechaNacimiento;
                          console.log("Response de las validaciones then: ",{responseCheck: responseCheck.data, photoData: photoData.data, validaciones: validaciones.data.data})
                          resolve({responseCheck: responseCheck.data, photoData: photoData.data, validaciones: validaciones.data.data});
                        })
                        .catch(() => {
                          const resp = photoData.data;
                          resp.fechaNacimiento = fechaNacimiento;
                          console.log("Response de las validaciones cathc: ",{responseCheck: null, photoData: photoData.data, validaciones: validaciones.data.data})
                          resolve({responseCheck: null, photoData: photoData.data, validaciones: validaciones.data.data});
                        });
                    } else {
                      this.registerIdCardCheckStatus(
                        jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                      )
                        .then(() => {

                        })
                        .catch(() => {
                          resolve({responseCheck: null, photoData: photoData.data, validaciones: validaciones.data.data});
                        });
                      reject({ error: { message: 'No se pudieron validar los datos de su cedula. Por favor vuelva a intentarlo.' } });
                    }
                  })
                  .catch((error) => {
                    GAException(error);
                    reject({ error: { message: 'No se pudieron comparar los datos de su cedula. Por favor vuelva a intentarlo.' } });
                  });
              }
              // PROCESAMIENTO CON ERROR
              else if (photoData.data.code === 1 || photoData.data.code === 2) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_LEER_DATOS.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `No se pudo completar la operacion. Por favor vuelva a intentarlo.${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'No se pudo completar la operacion. Por favor vuelva a intentarlo.' } });
              }
               // PROCESAMIENTO DE DOCUMENTO FRONTAL CONTRA CEDULA CON ERROR
               else if (photoData.data.code === 105 && photoData.data.similarity === 0) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_SELFIE_VS_FOTO_CI.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `No se pudo validar su cedula con la selfie. Favor vuelva a intentarlo.${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'No se pudo validar su cedula con la selfie. Favor vuelva a intentarlo.' } });
              }
              // PROCESAMIENTO DE DOCUMENTO FRONTAL CON ERROR
              else if (photoData.data.code === 101 || photoData.data.code === 103 || (photoData.data.code === 105 && photoData.data.data.similarity > 0)) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_FRONT_CI.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `No se pudo validar la parte frontal de su cedula con la selfie. Por favor vuelva a intentarlo con una nueva foto.${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'No se pudo validar la parte frontal de su cedula. Por favor vuelva a intentarlo con una nueva foto.' } });
              }
              // PROCESAMIENTO DE DOCUMENTO TRASERO CON ERROR
              else if (photoData.data.code === 102 || photoData.data.code === 104 || photoData.data.code === 106 || photoData.data.code === 107) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_BACK_CI.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `No se pudo validar la parte trasera de su cedula. Por favor vuelva a intentarlo con una nueva foto.${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'No se pudo validar la parte trasera de su cedula. Por favor vuelva a intentarlo con una nueva foto.' } });
              }
              // ERROR EN EL REGISTRO DE LOS DATOS
              else if (photoData.data.code === 108) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_LEER_DATOS.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `No se pudo validar los datos de su cedula.${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'No se pudo validar los datos de su cedula.' } });
              }
              // ERROR NO CORRESPONDE EL DOCUMENTO CON LA CEDULA
              else if (photoData.data.code === 109) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_NRO_DOC_CI_VS_CATASTRO.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `La cédula no corresponde con el número de documento con el que se registro.${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'La cédula no corresponde con el número de documento con el que se registro.' } });
              }
              // ERROR LA CEDULA ESTA VENCIDA
              else if (photoData.data.code === 111) {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_CI_VENCIDA.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `La cedula ingresada esta vencida${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: 'La cedula ingresada esta vencida' } });
              } else {
                this.registerIdCardCheckStatus(
                  jsonData.documentNumber, jsonData.transactionId, 0, token, auxSelfie, fechaNacimiento
                )
                  .then(() => {
                    const registroIncidencia = {
                      idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_DESCONOCIDO.id,
                      datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData(photoData.data, policeData.data, '')
                    };
                    incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                      .then(() => { })
                      .catch(() => {
                        resolve(photoData.data);
                      });
                  })
                  .catch(() => {
                    resolve(photoData.data);
                  });
                GAException(
                  {
                    message: `ERROR - ${photoData.data.message} - ${JSON.stringify(photoData.data)}  - ${JSON.stringify(jsonData)}`
                  }
                );
                reject({ error: { message: photoData.data.message } });
              }
            })
            .catch((error) => {
              GAException(error);

              const registroIncidencia = {
                idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_DESCONOCIDO.id,
                datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData({}, {}, '')
              };
              incidenciasService.crearIncidenciaRegistro(registroIncidencia)
                .then(() => { })
                .catch(() => {
                  resolve({});
                });

              // reject(error);{}
              reject({ error: { message: 'Ocurrio un error inesperado al obtener los Datos de Validacion de la Persona. Favor aguarde unos minutos y vuelva a intentarlo.' } });
            });
        } else {
          reject({ error: { message: photoData.data.txtReturn } });
        }
      })
      .catch((error) => {
        GAException(error);

        const registroIncidencia = {
          idTipoIncidencia: LISTA_INCIDENCIAS.INCIDENCIA_COMP_REGISTRO_DESCONOCIDO.id,
          datosIncidencia: incidenciasService.getIncidenciaRegistroFormatoData({}, {}, '')
        };
        incidenciasService.crearIncidenciaRegistro(registroIncidencia)
          .then(() => { })
          .catch(() => {
            resolve({});
          });

        // reject(error);
        reject({ error: { message: 'Ocurrio un error inesperado al Subir las Fotos. Favor aguarde unos minutos y vuelva a intentarlo.' } });
      });
  })

  registerIdCardCheckStatus = (documento, idTransaction, documentoValidadoFlag, token, selfie, fechaNacimiento) => new Promise((resolve, reject) => {
    let auxSelfie = '';
    let tipoImg = '';

    if (selfie !== '' && selfie !== null && selfie !== undefined) {
      auxSelfie = selfie.split(',')[1];
      tipoImg = `${selfie.split(',')[0]},`;
    }

    const catastroRequest = {
      documento,
      idTransaction,
      documentoValidadoFlag,
      selfie: auxSelfie,
      formato: tipoImg,
      fechaNacimiento,
    };

    try {
      axios.post(
        `${API_URL}/tarjetaPrepaga/v1/RegistroDimoPaso2`,
        {
          requestHeaderApp: API_REQUEST_HEADER,
          phoneData: API_REQUEST_PHONE_DATA,
          catastroRequest
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        },
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else if (response.data.header.codReturn === '9033') {
            reject({ error: { message: response.data.header.txtReturn } });
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  registerConditionsTerms = (documento, token) => new Promise((resolve, reject) => {
    const catastroRequest = {
      documento
    };

    try {
      axios.post(
        `${API_URL}/tarjetaPrepaga/v1/RegistroDimoAceptaTerminos`,
        {
          requestHeaderApp: API_REQUEST_HEADER,
          phoneData: API_REQUEST_PHONE_DATA,
          catastroRequest
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        },
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  verifyEmail = (documento, token) => new Promise((resolve, reject) => {
    const activacionEmailDimo = {
      documento,
      token,
    };

    try {
      axios.post(
        `${API_URL}/tarjetaPrepaga/v1/ValidarEmailCatastro`,
        {
          requestHeaderApp: API_REQUEST_HEADER,
          activacionEmailDimo,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token pruebalocal'
          }
        },
      )
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  });

  validarOtpCatastro = (documento, otp, codPromotor) => new Promise((resolve, reject) => {
    const activacionOtpDimo = {
      documento,
      otp,
      codPromotor

    };

    try {
      axios.post(
        `${API_URL}/tarjetaPrepaga/v1/validarOtpCatastro`,
        {
          requestHeaderApp: API_REQUEST_HEADER,
          activacionOtpDimo,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Token pruebalocal'
          }
        },
      )
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ message: response.data.header.txtReturn });
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  validarDimoUser = (documento) => new Promise((resolve, reject) => {
    const request = {
      documento,
    };

    try {
      axios.post(
        `${API_URL_DIMO}/ws_ControlUsuario`,
        request,
        CONFIG,
      )
        .then((response) => {
          if (response.data.codRespuesta === 0) {
            resolve(response.data.nombre);
          } else {
            reject({ message: response.data.txtRespuesta });
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  whiteListCheck = (documento) => new Promise((resolve, reject) => {
    const request = {
      documento,
    };

    try {
      axios.post(
        `${API_URL_DIMO}/ws_person_document_consult`,
        request,
        CONFIG,
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.codRespuesta === '0') {
            resolve(response.data.codRespuesta);
          } else {
            reject({ message: response.data.txtRespuesta });
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  updateUserCadastreData = (documento, nombre, apellido, selfie, pin) => new Promise((resolve, reject) => {
    let auxSelfie = '';
    let tipoImg = '';
    let flagSelfieModificada = false;

    if (selfie !== '' && selfie !== null && selfie !== undefined) {
      auxSelfie = selfie.split(',')[1];
      tipoImg = `${selfie.split(',')[0]},`;
      flagSelfieModificada = true;
    }

    const catastroRequest = {
      cedula: documento,
      nombre,
      apellido,
      flagSelfieModificada,
      selfie: auxSelfie,
      formato: tipoImg,
      otpSeguridad: pin?.otp,
      tokenIdTrxSeguridad: pin?.tokenIdTrx
    };

    try {
      axios.post(
        `${API_URL}/v1/modificarPerfilDatosBasicos`,
        {
          header: API_REQUEST_HEADER,
          data: catastroRequest
        },
        HEADERS_WITH_TOKEN()
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else if (response.data.header.codReturn === '9033') {
            reject({ error: { message: response.data.header.txtReturn } });
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject({ error: { message: 'Error en el servicio' } });
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  changeUserEmailRequest = (data) => new Promise((resolve, reject) => {
    // console.log('changeUserEmailRequest data', data)
    const dataPrm = {
      email: data.hasOwnProperty('email') ? data.email : '',
      otpSeguridad: data.hasOwnProperty('otpSeguridad') ? Number(data.otpSeguridad) : '',
      tokenIdTrxSeguridad: data.hasOwnProperty('tokenIdTrxSeguridad') ? data.tokenIdTrxSeguridad : '',
    };

    try {
      axios.post(
        `${API_URL}/v1/modificarPerfilEmail`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm
        },
        HEADERS_WITH_TOKEN()
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject({ error: { message: 'Error en el servicio de cambio de Email' } });
        });
    } catch (ee) {
      // console.log(ee);
      reject({ error: { message: 'Error en el servicio' } });
    }
  })

  changeUserEmailConfirm = (data) => new Promise((resolve, reject) => {
    const dataPrm = {
      token: data.hasOwnProperty('token') ? data.token : '',
      otp: data.hasOwnProperty('otp') ? data.otp : '',
    };

    try {
      axios.post(
        `${API_URL}/v1/validarEmailModificarPerfil`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm
        },
        HEADERS_WITH_TOKEN()
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject({ error: { message: 'Error en el servicio de cambio de Email' } });
        });
    } catch (ee) {
      // console.log(ee);
      reject({ error: { message: 'Error en el servicio' } });
    }
  })

  changeUserCellPhoneNumberRequest = (data) => new Promise((resolve, reject) => {
    const dataPrm = {
      numeroTelefono: data.hasOwnProperty('numeroTelefono') ? data.numeroTelefono : '',
      otpSeguridad: data.hasOwnProperty('otpSeguridad') ? Number(data.otpSeguridad) : '',
      tokenIdTrxSeguridad: data.hasOwnProperty('tokenIdTrxSeguridad') ? data.tokenIdTrxSeguridad : '',
    };

    try {
      axios.post(
        `${API_URL}/v1/modificarPerfilCelular`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm
        },
        HEADERS_WITH_TOKEN()
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject({ error: { message: 'Error en el servicio' } });
        });
    } catch (ee) {
      // console.log(ee);
      reject({ error: { message: 'Error en el servicio' } });
    }
  })

  changeUserCellPhoneNumberConfirm = (data) => new Promise((resolve, reject) => {
    const dataPrm = {
      token: data.hasOwnProperty('token') ? data.token : '',
      otp: data.hasOwnProperty('otp') ? data.otp : '',
    };

    try {
      axios.post(
        `${API_URL}/v1/validarCelularModificarPerfil`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm
        },
        HEADERS_WITH_TOKEN()
      )
        .then((response) => {
          // console.log('response', response)
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
          GAException(error);
          // console.log('error', error)
          reject({ error: { message: 'Error en el servicio de cambio de Email' } });
        });
    } catch (ee) {
      // console.log(ee);
      reject({ error: { message: 'Error en el servicio' } });
    }
  })

  reenviarOtpCatastro = (documento) => new Promise((resolve, reject) => {
    const dataPrm = {
      documento: documento !== undefined ? documento : '',
    };

    try {
      axios.post(
        `${API_URL}/v1/reenviarOtp`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Token pruebalocal'
          }
        },
      )
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ message: response.data.header.txtReturn });
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  registrarActividadFrontend = (tipo, ruta, data) => new Promise((resolve, reject) => {
    const dataPrm = {
      tipo: (tipo != undefined && tipo != null) ? tipo : '',
      ruta: (ruta != undefined && ruta != null) ? ruta : '',
      data: (data != undefined && data != null) ? data : '',
    };

    try {
      axios.post(
        `${API_URL}/v1/registrarActividadFrontend`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            // reject({ message: response.data.header.txtReturn });
            resolve({});
          }
        })
        .catch((error) => {
          GAException(error);
          // reject(error);
          resolve({});
        });
    } catch (ee) {
      // console.log(ee);
    }
  })

  obtenerCodPromotorCliente = () => new Promise((resolve, reject) => {
    const dataPrm = {};

    try {
      axios.post(
        `${API_URL}/v1/obtenerCodPromotorCliente`,
        {
          header: API_REQUEST_HEADER,
          data: dataPrm,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject(defaultResponseError(response.data.header.txtReturn));
          }
        })
        .catch((error) => {
          GAException(error);
          reject(error);
        });
    } catch (ee) {
      // console.log(ee);
      reject(ee);
    }
  })

  loginFidUser = (param) => new Promise((resolve, reject) => {
    axios.post(`${API_URL}/v1/fidelizacion/user/auth/login`, param)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  // DToken
  dTokenValidarSelfie = (documento, selfie) => new Promise((resolve, reject) => {
    
    const bodyFormData = new FormData();
    bodyFormData.append('selfie', this.dataURItoBlob(selfie));
    bodyFormData.set(
      'documento',
      documento
    );

    try {
      axios.post(
        `${API_URL}/v1/dtoken/validar/selfie`,
        bodyFormData,
        {
          headers: {
            ContentType: 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      )
        .then((response) => {
          if (response.data.header.codResultado=== '0') {
            resolve({ response: true, message: response.data.header.txtResultado });
          } else {
            reject({ response: false, message: response.data.header.txtResultado });
          }
        })
        .catch((error) => {
          // GAException(error);
          reject({ response: false, message: 'Error en el servicio de validacion de selfie para DToken.' });
        });
    } catch (ee) {
      // console.log(ee);
      reject({ response: false, message: 'Error en el servicio de validacion de selfie para DToken. Favor volver a intentar luego.' });
    }
  })

  generarOtpDtoken = ({ canal }) => new Promise((resolve, reject) => {
    // const dataPost = {
    //   documento: dataPrm.documento,
    // };

    axios.post(`${API_URL}/v1/dtoken/enviar/otp`, 
      { canal: canal}, 
      {
        headers: {
          ContentType: 'application/json',
          // Authorization: 'Basic Y2FiYWw6Q0BiQGwxNTA2',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
    })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  procesarOtpDtoken = ({ canal, otp, tokenTrx }) => new Promise((resolve, reject) => {
    // const dataPost = {
    //   documento: dataPrm.documento,
    // };

    axios.post(`${API_URL}/v1/dtoken/validar/otp`, 
      { 
        canal: canal ? canal : '',
        otp: otp ? otp : '',
        tokenTrx: tokenTrx ? tokenTrx : ''
      }, 
      {
        headers: {
          ContentType: 'application/json',
          // Authorization: 'Basic Y2FiYWw6Q0BiQGwxNTA2',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
    })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

}

const accountService = new AccountService();

export default accountService;
